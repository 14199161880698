import React from 'react';
import { populateTextCellComponent } from './populateTextCellComponent';
import { populateHeadingCellComponent } from './populateHeadingCellComponent';

export interface InlineChild {
  tagName?: string;
  type: string;
  value: string;
}
export interface SubChild {
  children?: InlineChild[] | undefined;
  position?: { start: { line: number; column: number; offset: number } };
  properties?: { href?: string; target?: string; style?: string };
  tagName?: string;
  type: string;
  value: string;
}
export interface NodeChildContent {
  children: SubChild[] | InlineChild[];
  tagName?: string;
  type: string;
  value: string;
}
export interface NodeForProcess extends NodeChildContent {
  children: NodeChildContent[];
  position: { start: { line: number; column: number; offset: number } };
  type: string;
}

export interface ElementObject {
  item: NodeForProcess;
  key: string;
}

export interface ElementProps {
  item: NodeForProcess;
  variant?:
    | 'copy1'
    | 'copy2'
    | 'copy3'
    | 'copy4'
    | 'order1'
    | 'order2'
    | 'order3'
    | 'order4'
    | 'display1'
    | 'display2';
  key: string;
}

let mainCaption = '' as string | undefined;

export const createUniqueKeyString = (element: SubChild) => {
  let uniqueKey = '' as string;
  if (element.position) {
    const { line, column, offset } = element.position.start;
    uniqueKey = `${line}_${column}-${offset}`;
  }
  return uniqueKey;
};

export const getHeadings = (rowOfHeadings: NodeChildContent) => {
  const { children: rowChildren } = rowOfHeadings;

  const allHeadings: React.JSX.Element[] = [];
  // if (rowChildren) {
  const headings = rowChildren.filter((child) => child.type === 'element');

  const [headingsRow] = headings as NodeChildContent[];

  if (headingsRow) {
    const headingCells = headingsRow.children?.filter((child) => child.type === 'element');

    headingCells?.forEach((cell: SubChild) => {
      const uniqueKey = createUniqueKeyString(cell);
      const singleCellContent = populateHeadingCellComponent(cell, uniqueKey);
      allHeadings.push(singleCellContent);
    });
  }

  return allHeadings;
};

export const getMainCaption = (captionChild: NodeChildContent) => {
  const { tagName, children: subChildren } = captionChild;

  if (tagName === 'caption') {
    mainCaption = subChildren?.[0]?.value;
  }

  return mainCaption;
};

export const getCells = (row: NodeChildContent) => {
  const { children: rowChildren } = row;

  const cellsContentsForRow: React.JSX.Element[] = [];

  const cellsOfElementTypeOnly = rowChildren?.filter((cell: SubChild) => cell.type === 'element');

  cellsOfElementTypeOnly.forEach((cell: SubChild) => {
    const uniqueKey = createUniqueKeyString(cell);

    // if cell does not have children -
    // means it is a td with simple content
    const cellElement: SubChild[] = [];
    if (cell?.children) {
      let addThisElement = {} as SubChild;
      const { children } = cell;
      const [singleElement] = children;

      const subElement = children.find((el) => el.type === 'element');

      if (children.length === 1 && singleElement.type === 'text') {
        addThisElement = singleElement;
      } else if (subElement) {
        addThisElement = subElement;
      }

      cellElement.push(addThisElement);
    }

    let checkCellElement: SubChild;

    checkCellElement = cell;

    if (cellElement) {
      const [cellElementValue] = cellElement;

      checkCellElement = cellElementValue;
    }

    const singleCellContent = populateTextCellComponent(checkCellElement, uniqueKey);
    cellsContentsForRow.push(singleCellContent);
  });

  return cellsContentsForRow;
};

export const getContents = (rows: NodeForProcess) => {
  const { children: tableRows } = rows;

  const allContents: React.JSX.Element[][] = [];

  const rowOfElementsTypeOnly = tableRows?.filter(
    (row: NodeChildContent) => row.type === 'element',
  );

  rowOfElementsTypeOnly?.forEach((rowOfCells) => {
    const allCells: React.JSX.Element[] = getCells(rowOfCells);

    if (allCells?.length > 0) {
      allContents.push(allCells);
    }
  });

  return allContents;
};

export const findTableContent = ({ item, key }: ElementProps) => {
  const { children } = item;

  const tableCaption = children.find((child) => child.tagName === 'caption') as NodeChildContent;
  const tableHead = children.find((child) => child.tagName === 'thead') as NodeChildContent;
  const tableBody = children.find((child) => child.tagName === 'tbody') as NodeForProcess;

  const caption = tableCaption ? getMainCaption(tableCaption) : '';
  const headings = tableHead ? getHeadings(tableHead) : null;
  const contents = getContents(tableBody);

  const tableData = {
    captionData: caption,
    headingsData: headings,
    contentData: contents,
    key,
  };

  return tableData;
};
