import { ContentFragment } from '@oneaudi/falcon-tools';

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    theme: string;
    bodyTable: string;
  };
}

export interface AppContent {
  __type?: 'default';
  theme: string;
  bodyTable: string;
}

function mapContent(content: AppContent | FalconContent): AppContent {
  if (content?.__type !== 'aem-headless') {
    return content;
  }

  return normalizedContent(content);
}

const normalizedContent = (content: FalconContent): AppContent => {
  const { fields } = content;
  const { theme, bodyTable } = fields;

  const contentForCMS: AppContent = {
    theme,
    bodyTable,
  };

  const formattedContent: AppContent = {
    ...contentForCMS,
  };

  return formattedContent;
};

export default mapContent;
