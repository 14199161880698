import React from 'react';
import parse from 'html-react-parser';
import { Text } from '@audi/audi-ui-react';
import { InlineChild, SubChild } from './tableContentUtils';
import { ListComponent as List, ListProps } from '../List/ListComponent';
import { checkForInlineElements } from '../EditorialTable/editorialTableUtils';

export const populateTextCellComponent = (
  cellObject: SubChild,
  index: string,
): React.JSX.Element => {
  const textOnly: SubChild[] = [];
  let isList = false;
  let list: SubChild[] = [];
  let listType = 'bullet' as 'bullet' | 'decimal';
  let listProps = {} as ListProps;

  if (cellObject?.children) {
    const elementChildrenArray: SubChild[] = cellObject.children.filter(
      (child: InlineChild) => child.type === 'element',
    );

    const [singleElement] = elementChildrenArray;

    if (singleElement?.tagName) {
      if (cellObject.tagName === 'ul' || cellObject.tagName === 'ol') {
        isList = true;

        list = [...elementChildrenArray];

        listType = cellObject.tagName === 'ul' ? listType : 'decimal';
        listProps = {
          itemsForList: list,
          variantType: listType,
          uniqueKey: index,
        };
      }
    } else {
      textOnly.push(cellObject);
    }
  } else {
    textOnly.push(cellObject);
  }

  return isList ? (
    List(listProps)
  ) : (
    <Text as="span" variant="copy1" key={index}>
      {parse(checkForInlineElements(textOnly).join(''))}
    </Text>
  );
};
