import sanitizeHtml from 'sanitize-html';
import { SubChild } from '../reconstructWithDesignComponents/tableContentUtils';

export const allowedTagsAndAttributes = {
  allowedTags: [
    'b',
    'u',
    'i',
    'em',
    'strong',
    'a',
    'ul',
    'ol',
    'li',
    'span',
    'table',
    'tbody',
    'thead',
    'th',
    'tr',
    'td',
    'caption',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel'],
    table: ['data-testid', 'style', 'border'],
    td: ['style'],
    span: ['style'],
  },
  allowedStyles: {
    span: {
      'text-decoration': [/^underline$/],
    },
    table: {
      'border-collapse': [/^collapse$/],
      width: [/^100%$/],
    },
    td: {
      width: [/^([0-9]*[.])?[0-9]+$/],
    },
  },
};

export const convertToSanitzedString = (text: string) => {
  const sanitized = sanitizeHtml(text, allowedTagsAndAttributes);

  return sanitized;
};

export const checkForInlineElements = (childrenArray: SubChild[]) => {
  const textStyles = childrenArray?.map((item: SubChild) => {
    let elementValue = item.value;

    if (item?.children) {
      const { properties, tagName, value } = item;
      const [singleElement] = item.children;

      const { value: innerItemValue, tagName: innerTagName } = singleElement;

      const innerValue = innerItemValue || value;

      const currentTagName = innerTagName || tagName;

      const linkHref = properties?.href;

      const linkTarget = properties?.target;

      const spanStyle = properties?.style;

      if (currentTagName === 'a') {
        elementValue = `<${currentTagName} href="${linkHref}" target="${linkTarget}">${innerValue}</${currentTagName}>`;
      } else if (currentTagName === 'td') {
        elementValue = `<span style=${spanStyle}>${innerValue}</span>`;
      } else {
        elementValue = `<${currentTagName} style=${spanStyle}>${innerValue}</${currentTagName}>`;
      }
    }

    return elementValue;
  });

  return textStyles;
};

export const createKey = (value1: string, value2: number) => {
  const sum = parseFloat(value1) + value2;
  return sum.toString();
};
