import React from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableColumnHeadingCell,
  TableCell,
} from '@audi/audi-ui-react';
import { createKey } from '../EditorialTable/editorialTableUtils';

interface TableProps {
  headings?: React.JSX.Element[] | null;
  caption?: string;
  contents: React.JSX.Element[][];
  uniqueKey: string;
}

export const TableComponent = ({ headings, caption, contents, uniqueKey }: TableProps) => {
  return (
    <Table displayMode="table" caption={caption} fixedLayout>
      {headings && headings?.length > 0 && (
        <TableHeader>
          <TableRow>
            {headings?.map((heading: React.JSX.Element) => (
              <TableColumnHeadingCell key={heading.key}>{heading}</TableColumnHeadingCell>
            ))}
          </TableRow>
        </TableHeader>
      )}
      {contents?.length > 0 && (
        <TableBody>
          {contents?.map((row: React.JSX.Element[], index) => (
            <TableRow key={createKey(uniqueKey, index)}>
              {row?.map((cell: React.JSX.Element) => <TableCell key={cell.key}>{cell}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
};
