import React from 'react';
import { List, ListItem } from '@audi/audi-ui-react';
import parse from 'html-react-parser';
import { SubChild } from '../reconstructWithDesignComponents/tableContentUtils';
import { createKey } from '../EditorialTable/editorialTableUtils';

export interface ListProps {
  itemsForList: SubChild[];
  variantType: 'bullet' | 'decimal';
  uniqueKey: string;
}

export const ListComponent = ({
  itemsForList,
  variantType,
  uniqueKey,
}: ListProps): React.JSX.Element => {
  const listItems = itemsForList.map((listItem: SubChild, index: number) => {
    let listItemValue;

    if (listItem?.children) {
      const [listItemElement] = listItem.children;

      const { value } = listItemElement;
      const listItemContent = parse(`<span>${value}</span>`) as React.JSX.Element;

      listItemValue = listItemContent;
    }

    return <ListItem key={createKey(uniqueKey, index)}>{listItemValue}</ListItem>;
  });

  return (
    <List variant={variantType}>
      {listItems.map((listItem: React.JSX.Element) => {
        return listItem;
      })}
    </List>
  );
};
