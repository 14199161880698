import React from 'react';
import parse from 'html-react-parser';
import { Text } from '@audi/audi-ui-react';
import { SubChild } from './tableContentUtils';
import { checkForInlineElements } from '../EditorialTable/editorialTableUtils';

export const populateHeadingCellComponent = (
  cellObject: SubChild,
  index?: string,
): React.JSX.Element => {
  const textOnly: SubChild[] = [cellObject];

  return (
    <Text as="span" variant="copy1" weight="bold" key={index}>
      {parse(checkForInlineElements(textOnly).join(''))}
    </Text>
  );
};
