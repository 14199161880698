import React from 'react';
import {
  AudiPlatformProvider,
  audiDarkTheme,
  audiLightTheme,
  Layout,
  LayoutItem,
} from '@audi/audi-ui-react';
import { convertToSanitzedString } from './editorialTableUtils';
import { reconstructWithDesignComponents } from '../reconstructWithDesignComponents/reconstructWithDesignComponents';
import { TableComponent } from '../TableComponent/TableComponent';
import { Container } from './style';

export interface EditorialTableTableContent {
  bodyTable: string;
  theme: string;
}

type Props = {
  content: EditorialTableTableContent;
};

export const EditorialTable = ({ content }: Props) => {
  const { bodyTable, theme } = content;
  const sanitzizedString = convertToSanitzedString(bodyTable);

  const dataForTable = reconstructWithDesignComponents(sanitzizedString);
  const { captionData, headingsData, contentData, key } = dataForTable;

  return (
    <AudiPlatformProvider theme={theme === 'dark' ? audiDarkTheme : audiLightTheme}>
      {bodyTable && (
        <Container theme={theme}>
          <Layout data-test-id="title" direction="column">
            <LayoutItem>
              <TableComponent
                headings={headingsData}
                caption={captionData}
                contents={contentData}
                uniqueKey={key}
              />
            </LayoutItem>
          </Layout>
        </Container>
      )}
    </AudiPlatformProvider>
  );
};

export default EditorialTable;
