import { unified } from 'unified';
import type { Node } from 'unist';
import rehypeParse from 'rehype-parse';
import rehypeSanitize from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';
import { visit } from 'unist-util-visit';
import { NodeForProcess, createUniqueKeyString, findTableContent } from './tableContentUtils';

export interface TableDataContent {
  captionData?: string | undefined;
  headingsData?: React.JSX.Element[] | null;
  contentData: React.JSX.Element[][];
  key: string;
}

export const reconstructWithDesignComponents = (contentString: string) => {
  let tableComponent = {} as TableDataContent;

  unified()
    .use(rehypeParse, {
      fragment: true,
    })
    .use(() => {
      return (tree: Node) => {
        visit(tree, 'element', (node: NodeForProcess) => {
          const uniqueKey = createUniqueKeyString(node);

          const elementObject = {
            item: node,
            key: uniqueKey,
          };

          if (node?.tagName === 'table') {
            const component = findTableContent(elementObject);

            if (component) {
              tableComponent = component;
            }
          }
        });
      };
    })
    .use(rehypeSanitize)
    .use(rehypeStringify)
    .processSync(contentString);

  return tableComponent;
};
